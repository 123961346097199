
import './TypingBubbles.css';

function TypingBubbles({ bright = false }) {
  return (
    <span className={`bubble-dots ${bright ? 'bright' : ''}`}>
      <svg className="dot one" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
      <svg className="dot two" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
      <svg className="dot three" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
    </span>
  );
}

export default TypingBubbles;