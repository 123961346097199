interface ThumbsDownProps {
  style?: React.CSSProperties
}

export function ThumbsDownIcon({ style: overrides }: ThumbsDownProps) {
  const style = {
    width: '1rem',
    height: '1rem',
    fill: 'currentColor',
    ...overrides,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.664 293.664" style={style}>
      <path d="M292.028,116.218c0-13.656-11.071-24.728-24.728-24.728h-5.52c7.023-4.362,11.703-12.142,11.703-21.018  c0-13.656-11.071-24.728-24.728-24.728h-7.994c7.023-4.362,11.703-12.142,11.703-21.018C252.464,11.071,241.393,0,227.737,0  c-11.885,0-124.134,0.001-126.185,0.001c-19.301,0-36.35,9.82-46.417,24.727H22.112c-11.308,0-20.476,9.167-20.476,20.476v79.639  c0,11.308,9.167,20.475,20.476,20.475H46.94c2.224,9.651,7.128,18.504,14.229,25.784c40.383,41.399,66.244,78.558,82.754,108.385  c7.338,13.257,20.807,17.498,33.142,11.53c11.074-5.358,16.361-17.871,13.01-29.298c-5.82-19.907-11.949-43.293-29.095-75.029  h92.113c13.656,0,24.728-11.071,24.728-24.728c0-8.876-4.679-16.656-11.703-21.018h1.18  C280.956,140.945,292.028,129.874,292.028,116.218z"/>
    </svg>
  )
}