import { observer } from "mobx-react-lite";
import { Flex } from "@radix-ui/themes";
import './ProgressBar.css';

interface ProgressBarProps {
  size: number;
  current: number;
  red: boolean;
}

export const ProgressBar = observer(({size, current, red}: ProgressBarProps) => {
  return (
    <Flex className={'progress ' + (red ? 'red' : '')}>
      {Array(size).fill(0).map((_, i) => (
        <div key={i} className={'bar ' + (current === i ? 'pulse' : current > i ? 'on' : 'off')}></div>
      ))}
    </Flex>
  )
})