export function EyeClosedIcon() {
  const style = {
    height: '1.25em',
    width: '1.25em',
    fill: 'currentColor',
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect x="0" fill="none" width="24" height="24" />
      <g>
        <path d="M1 12s4.188-6 11-6c.947 0 1.84.12 2.678.322L8.36 12.64C8.133 12.14 8 11.586 8 11c0-.937.335-1.787.875-2.47C6.483 9.344 4.66 10.917 3.62 12c.68.707 1.696 1.62 2.98 2.398L5.15 15.85C2.498 14.13 1 12 1 12zm22 0s-4.188 6-11 6c-.946 0-1.836-.124-2.676-.323L5 22l-1.5-1.5 17-17L22 5l-3.147 3.147C21.5 9.87 23 12 23 12zm-2.615.006c-.678-.708-1.697-1.624-2.987-2.403L16 11c0 2.21-1.79 4-4 4l-.947.947c.31.03.624.053.947.053 3.978 0 6.943-2.478 8.385-3.994z" />
      </g>
    </svg>
  );
}
;
