/// <reference path="global.d.ts" />
import React, { useEffect, useState, useRef } from 'react';
import { Flex, Text, Button, Container, Avatar, Card, IconButton, Box, Badge, Grid, Skeleton, Dialog } from '@radix-ui/themes';
import { ArrowDownIcon, PlayIcon, UpdateIcon } from '@radix-ui/react-icons';
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import TypingBubbles from './components/TypingBubbles';
import Conversation, { getConversation, AttachmentItem, PromptMessage, Link } from './Conversation';
import './App.css';
import type { Language } from './languages';
import { getLanguage } from './languages';
import { observer } from 'mobx-react-lite';
import { fetchLogs } from './api';
import locations from './locations.json';

// import confetti from 'canvas-confetti';

import { RecordIcon } from './icons/RecordIcon';
// import { EyeClosedIcon } from './icons/EyeClosedIcon';
import { speak, writeError } from './api';
import { ReceivedMessage, MessageOptions, SentMessage } from './components/Message';
import { HiddenMessage } from './components/Message';
import { SettingsProps, Settings } from './components/Settings';
import { debounce } from './debounce';
import { Audio, AudioState, audio as audioObserver } from './audio/audio';
import { ProgressBar } from './components/ProgressBar';

let disableScroll = false;
const scrollToBottom = () =>
  window.scroll({
    behavior: 'smooth',
    top: document.body.scrollHeight + 100,
  });

interface LogEvent {
  id: string;
  ip: string;
  event: UserInfoLog[] | any[];
  time: string;
}

interface ErrorEvent extends LogEvent {
  error: true;
  event: ErrorInfoLog;
}

type Event = LogEvent | ErrorEvent;

type ErrorInfoLog = [string, { stack: string, name: string, message: string }];

type UserInfoLog = [
  'USER_INFO', {browser: string, language: string}
];

const DAY_IN_MS = 24 * 60 * 60 * 1000;

function getDaysAgo(time: string) {
  const midnight = Date.parse(new Date().toLocaleDateString());
  const today = new Date().getTime() - midnight;
  const diff = new Date().getTime() - Date.parse(time);
  const daysAgo = diff < today ? null : Math.ceil((diff - today) / DAY_IN_MS);
  return daysAgo;
}

type LocationData = { city: string, region_code: string; };
type LocationLookup = { [ip: string]: LocationData };

interface LogViewerProps {
  logs: { events: Event[]};
}

const LogViewer = React.memo(observer(({ logs: { events } }: LogViewerProps) => {
  const ips = events.reduce(
    (a, n) => { a[n.id] = (a[n.id] ?? n.ip); return a; },
    {} as { [key: string]: string }
  );

  const otherLocations = events.filter(e => e.event[0] === 'LOCATION').map(e => e.event[1] ?? {});
  const location = (id: string) => locations.concat(otherLocations).find(l => l.ip === ips[id]);

  return (
    <Dialog.Root open={true}>
      <Dialog.Content>
        <Dialog.Title>Logs</Dialog.Title>
        <Card my="2">
          <Grid columns="auto minmax(0, 1fr)" gapY="1" gapX="3">
            {events.slice(0).reverse().filter(l => location(l.id)).map((log, index) => {
              const daysAgo = getDaysAgo(log.time);
              
              const eventData = (log.event[0] === 'USER_INFO') ? (
                <Grid columns="auto minmax(0, 1fr)" gapY="1" gapX="3">
                  <Text size="1" weight='medium' align='right'>Browser</Text>
                  <Text size="1">{log.event[1].browser}</Text>
                  <Text size="1" weight='medium' align='right'>Language</Text>
                  <Text size="1">{log.event[1].language}</Text>
                </Grid>
              ) : <Text size="1">{log.event.map(a => JSON.stringify(a)).join(', ')}</Text>;

              return (
                <React.Fragment key={`${log.id}-${log.time}-${index}`}>
                  <Flex direction='column' className="alternate-bg">
                    {daysAgo && <Text size="1">{daysAgo} day{daysAgo > 1 ? 's' : ''} ago</Text>}
                    <Text size="1">{new Date(Date.parse(log.time)).toLocaleTimeString()}</Text>
                    <Text size="1">{location(log.id)?.city}, {location(log.id)?.region_code}</Text>
                  </Flex>
                  <Text className="alternate-bg">{eventData}</Text>
                </React.Fragment>
              );
            })}
          </Grid>
        </Card>
      </Dialog.Content>
    </Dialog.Root>
  );
}))

const App = React.memo(observer(() => {
  const [logs, setLogs] = useState<{ events: Event[], locations: LocationLookup } | null>(null);

  useEffect(() => {
    if (window.location.search.includes('logs')) {
      fetchLogs().then(setLogs);
    }
  }, [])

  const [language, setLanguage] = useState(
    getLanguage(
      decodeURIComponent(window.location.hash).substring(1)
    )
  );

  const conversation = getConversation(language);

  const [started, setStarted] = useState(false);

  if (conversation == null) {
    return;
  }

  const onStart = async () => {
    try {
      setStarted(true);
      await conversation.start();

    } catch { }
  }

  const onLanguageChange = (language: Language) => {
    setStarted(false);
    window.location.hash = language.name
    setLanguage(language);
  };

  const settingsProps: SettingsProps = {
    defaultOpen: audioObserver.is('init'),
    doneText: conversation.started === false ? 'Start' : 'Continue',
    language,
    onLanguageChange,
    onDone: async () => {
      if (!started) {
        await onStart();
      }
      await audioObserver.record(
        (file) => conversation.continue(file)
      );
    },
  }
  const chatProps: ChatProps = {
    conversation,
    audio: audioObserver,
  };

  return (
    <ChatContainer {...chatProps} settingsProps={settingsProps} >
      <>
        {logs && <LogViewer logs={logs} />}
        <Chat {...chatProps} />
      </>
    </ChatContainer>
  )
}));

type ChatContainerProps = {
  settingsProps: SettingsProps;
  conversation: Conversation;
  audio: Audio;
  children: JSX.Element | JSX.Element[];
};

const ChatContainer = React.memo(observer(({ children, settingsProps, conversation, audio }: ChatContainerProps) => {
  const sizingRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (sizingRef.current) {
      const size = +getComputedStyle(sizingRef.current).fontSize.replace('px', '');
      if (size > 20) {
        document.body.classList.add('scale-container')
      }
    }
  }, [sizingRef]);

  const shock = conversation.shock;
  const level = conversation.level;
  const isFinale = level?.isFinale;
  const challengeIndex = level?.currentChallengeIndex ?? -1;

  return (
    <Flex style={{ flexGrow: 1 }} direction="column">
      <Container className="header" size="1" grow="0" position="sticky" top="0">
        <Flex align="center" direction="column" pb="2">
          <Avatar mt="4" className={'avatar ' + (shock ? 'shock' : '')} src={"/spanish-tutor.png"} fallback="T" radius="full" size="4" />
          <Text size="1" my="1">Buddy</Text>
          
            <Box className='level' my="1" style={{width: '75%'}}>
              <Card>
                <Flex align="center" direction="column" gap="3" mb={(!!level && challengeIndex !== -1) ? '3' : '0'}>
                  <Badge variant='solid' color={ isFinale ? 'red' : 'blue' } radius='full' size="2">
                    <Text ml="2" mr="1">{isFinale ? 'ULTIMATE TEST' : 'WORDS'}</Text>
                    {isFinale || <Badge variant='surface' style={{marginRight: '-0.5rem'}} size="1">{conversation.wordCount}</Badge>}
                  </Badge>
                </Flex>
                {!!level && challengeIndex !== -1 && (
                  <ProgressBar red={isFinale ?? false} size={level.totalChallenges} current={challengeIndex} />
                )}
              </Card>
            </Box>
          <Flex position="absolute" style={{ top: '2rem', right: '2rem' }}>
            <Settings {...settingsProps} />
          </Flex>
        </Flex>
      </Container>
      <Flex style={{ maxWidth: '100vw', overflow: 'hidden' }} direction="column" grow="1">
        {children}
      </Flex>
      <RecordFooter conversation={conversation} audio={audio} />
      <span ref={sizingRef} style={{ fontSize: '16px', font: '-apple-system-body' }}></span>
    </Flex>
  )
}));

type ChatProps = {
  conversation: Conversation;
  audio: Audio;
}

const RECEIVED_OPTIONS: ['Translate' | 'Show' | 'Hide' | 'Play' | 'Retry', React.ReactNode][] = [
  ['Play', <PlayIcon />],
  // ['Show', <EyeOpenIcon />],
  // ['Hide', <EyeClosedIcon />],
  ['Retry', <UpdateIcon />]
];

const SENT_OPTIONS: ['Play' | 'Retry', React.ReactNode][] = [
  ['Play', <PlayIcon />],
  ['Retry', <UpdateIcon />],
];

const MessageContent = ({ message }: { message: PromptMessage }) =>
  <>{message.content.split('\n').map((line, i) => (
    <Text key={i} size={message.role === 'assistant' && message.size || '3'}>{line}</Text>
  ))}
  </>;

type MessageWithOptionsProps = {
  message: PromptMessage;
  retry: boolean;
  conversation: Conversation;
  audio: Audio;
  messageProps: HTMLMotionProps<"div">;
}

const MessageWithOptions = React.memo(observer(React.forwardRef<HTMLDivElement, MessageWithOptionsProps>(({ message, messageProps, conversation, audio, retry }, ref) => {
  const [visiblity, setVisibility] = useState(false);

  const onMessageClick = async (name: string, message: PromptMessage) => {
    const selection = window.getSelection()?.toString();
    switch (name) {
      case 'Play':
        if (message.role === 'assistant') {
          const playRate = message.role === 'assistant' ? message.rate : 1;
          if (selection) {
            const selectionAudioUrl = await speak(selection, message.languageCode);
            await audio.play(selectionAudioUrl, { rate: playRate });
          } else {
            const audioUrl = await speak(message.content, message.languageCode);
            await audio.play(audioUrl, { rate: playRate });
          }
        } else {
          if (message.id) {
            await audio.play(URL.createObjectURL(audio.recordedAudio[message.id]));
          }
        }
        break;
      case 'Show':
        setVisibility(true);
        break;
      case 'Hide':
        setVisibility(false);
        break;
      case 'Retry':
        conversation.level?.retry(message);
        break;
      default:
        break;
    }
  }

  const marginTop = message.role === 'assistant' && message.size === '5' && 'var(--space-4)' || undefined

  return (
    message.role === 'assistant' ? (
      <motion.div ref={ref} {...messageProps} style={{ originX: 0, marginTop }}>
        <ReceivedMessage>
          <Flex direction="column" position="relative">
            {!message.hide ?
              <>
                <MessageContent message={message} />
                <MessageOptions
                    variant="soft"
                    options={RECEIVED_OPTIONS.filter(a => (retry && a[0] === 'Retry') )}
                    onClick={(name) => onMessageClick(name, message)} />
              </> :
              <>
                <HiddenMessage hide={!visiblity}>
                  <MessageContent message={message} />
                </HiddenMessage>
                <MessageOptions
                  variant="soft"
                  options={RECEIVED_OPTIONS.filter(a =>
                    (!visiblity && a[0] !== 'Hide') || (!!visiblity && a[0] !== 'Show')
                  )}
                  onClick={(name) => onMessageClick(name, message)} />
              </>
            }
            {message.links && (
              <MessageLinks links={message.links} conversation={conversation} audio={audio} />
            )}
          </Flex>
        </ReceivedMessage>
      </motion.div>
    ) : (
      <motion.div ref={ref} {...messageProps} style={{ originX: 1 }}>
        <SentMessage tapbacks={message.tapbacks}>
          <Flex direction="column" align="end" position="relative" style={{ overflow: 'scroll' }}>
            <MessageContent message={message} />
            <MessageOptions
              options={SENT_OPTIONS.filter(([key]) => (retry && key === 'Retry') || (
                key === 'Play' && message.role === 'user' && message.id && audio.recordedAudio[message.id]
              ))}
              onClick={(name) => onMessageClick(name, message)} />
          </Flex>
        </SentMessage>
      </motion.div>
    )
  )
})));

type MessageLinksProps = {
  conversation: Conversation;
  audio: Audio;
  links: Link[];
}

const MessageLinks = React.memo(observer(({ links: attachment, conversation, audio }: MessageLinksProps) => {
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [attachmentData, setAttachmentData] = useState<AttachmentItem[] | null>(null);
  
  useEffect(() => {
    disableScroll = true;
    setAttachmentData(null);
    if (attachment[attachmentIndex]) {
      conversation.level?.getAttachments(attachment[attachmentIndex])
        .then(o => {
          setAttachmentData(o);
          setTimeout(() => {
            disableScroll = false
          }, 100);
        })
        .catch(e => writeError(e));
    }
  }, [attachmentIndex])

  return (
    <Box my="1">
      {attachment.map(({ name }, i) => (
        <Button key={i} mr="2" mt="2" color="blue" variant='soft' size="1"
          disabled={attachmentIndex === i}
          onClick={() => setAttachmentIndex(i)}
          // style={{ color: conversation.level.started ? 'transparent' : undefined }}
        >{name}</Button>
      ))}
      {attachmentIndex > -1 && (
        <Skeleton loading={attachmentIndex > -1 && !attachmentData}>
          <Card mt="3" mb="1">
            <Grid columns="auto minmax(0, 1fr)" gapY="1" gapX="3">
              {attachmentData?.length && attachmentData.map((c, i) => (
                <React.Fragment key={i}>
                  <Text weight="bold" size="1" align="right" mt="1">{c.name || <span>&nbsp;</span> }</Text>
                  <Flex>{c.name === 'Audio' ? (
                    <IconButton color="blue" variant='soft' onClick={async () => {
                      audio.play(await speak(c.value, conversation.language.code))
                    }}><PlayIcon /></IconButton>
                  ) : c.value}</Flex>
                </React.Fragment>
              ))}
            </Grid>
          </Card>
        </Skeleton>
      )}
    </Box>
  );
}));

const Chat = React.memo(observer(({ conversation, audio }: ChatProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  }

  const messages = conversation.messages;

  useEffect(() => {
    scrollToBottom()
  }, [messages?.length, audio.state]);

  // useEffect(() => {
  //   confetti({
  //     particleCount: 100,
  //     startVelocity: 30,
  //     spread: 360,
  //     origin: {
  //       x: Math.random(),
  //       // since they fall down, start a bit higher than random
  //       y: Math.random() - 0.2
  //     }
  //   });
  // }, []);

  const messageProps: HTMLMotionProps<"div"> = {
    layout: true,
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
    transition: {
      opacity: { duration: 0.2 },
      layout: {
        type: 'just',
        bounce: 0.4,
        duration: 0.2,
        // duration: Math.abs(i - ((conversation.messages.length - 1))) * 0.15 + 0.45,
      },
    }
  };

  return (
    <Container size="1" m="2" grow="1">
      <AnimatePresence initial={false} mode="popLayout">
        {messages?.map((message, i) => (
          <MessageWithOptions key={i} {...{message, conversation, audio, messageProps, retry: message.role === 'user' || (messages[i + 1]?.role ?? 'user') === 'user'}} />
        ))}
        {
          !audio.is('muted') && !audio.is('init') &&
          <motion.div key="recording" {...messageProps}>
            <SentMessage recording={!audio.is('muted', 'mute') ? 'on' : audio.is('loading') ? 'loading' : undefined} bulge={audio.is('on')}>
              <Text size="5">
                {audio.is('loading') ? 'Loading...' : !audio.is('muted', 'mute') ? (
                  <>
                    <RecordIcon width='1.25rem' height='1.25rem' marginBottom='-0.15rem' /> Recording &nbsp;
                  </>
                ) : 'Processing...'}
              </Text>
            </SentMessage>
          </motion.div>
        }
        {
          conversation.typing === 'typing' &&
          <motion.div key="typing" {...messageProps}>
            <Box mt="2">
              <ReceivedMessage bulge={true}>
                <TypingBubbles />
              </ReceivedMessage>
            </Box>
          </motion.div>
        }
      </AnimatePresence>
      {/*         
      <ReceivedMessage>
        <Text size="9">🏆🏆🏆</Text>
      </ReceivedMessage>
      <ReceivedMessage>
        <Text size="6">Congratulations!</Text>
      </ReceivedMessage>
      <ReceivedMessage>
        You've made it to the 5 minute mark.
      </ReceivedMessage> 
    */}
      <div ref={messagesEndRef} />
    </Container>
  );
}));

type RecordFooterProps = {
  conversation: Conversation;
  audio: Audio;
}

const RecordFooter = React.memo(observer(({ conversation, audio }: RecordFooterProps) => {
  const recordingColors: Record<AudioState, 'red' | 'green' | 'gray' | 'gray'> = {
    idle: 'green',
    on: 'green',
    init: 'gray',
    loading: 'gray',
    mute: 'gray',
    muted: 'red',
  };

  const isAtBottom = () => window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight;
  const [atBottom, setAtBottom] = useState(isAtBottom());

  useEffect(() => {
    const listener = debounce(() => {
      const current = isAtBottom();
      if (current !== atBottom) {
        setAtBottom(current);
      }
    }, 500);

    window.addEventListener('scroll', listener);
    const resizer = new ResizeObserver(listener);
    resizer.observe(document.body);
    return () => {
      window.removeEventListener('scroll', listener);
      resizer.unobserve(document.body);
    };
  }, [atBottom, setAtBottom]);

  useEffect(() => {
    const listener = debounce(() => {
      if (!isAtBottom() && !disableScroll) {
        scrollToBottom();
      }
    }, 50);

    const resizer = new ResizeObserver(listener);
    resizer.observe(document.body);
    return () => {
      resizer.unobserve(document.body);
    };
  }, []);

  const onScrollDownClick = () => { setAtBottom(true); scrollToBottom() };

  return (
    <Container size="1" p="2" pb="5" grow="0" position="sticky" bottom="0">
      <Flex justify="center" align="center" gap="2">
        {!audio.is('init') && <Button
          name="record"
          color={recordingColors[audio.state]}
          size="4"
          style={{ width: '0', border: '1px solid gray' }}
          radius="full"
          onClick={async () => {
            if (!audio.is('muted')) {
              await audio.recordStop();
            } else {
              await audio.record(async (file) => {
                await conversation.continue(file);
              });
            }
          }}
        >
          <Flex justify="center" align="center">
            {audio.is('loading', 'mute') ? <TypingBubbles bright /> : <RecordIcon muted={!audio.is('muted')} />}
          </Flex>
        </Button>}
        {!atBottom && (
          <Flex className='bounce' position="absolute" right="0" pr="5">
            <IconButton size="4" variant='surface' radius="full" onClick={onScrollDownClick}><ArrowDownIcon /></IconButton>
          </Flex>
        )}
      </Flex>
    </Container>
  )
}));

export default App;
