import { ThumbsDownIcon } from "./ThumbsDownIcon";

export function ThumbsUpIcon() {
  const style = {
    transform: 'rotate(0.5turn) scaleX(-1)'
  };

  return (
    <ThumbsDownIcon style={style} />
  )
}