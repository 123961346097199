import languagesData from './languages.json';
import scaleData from './languageScale.json';

type LanguageName = keyof typeof languagesData;
type ScaleName = keyof typeof scaleData;

export type LanguageCode = {
  [K in keyof typeof languagesData]: typeof languagesData[K]['code']
}[keyof typeof languagesData];

export type Language = {
  name: LanguageName;
  error: number;
  scale: {
    name: ScaleName;
    levels: { [level: string]: number };
  };
  native: { min: number; max: number };
  code: LanguageCode;
};

const languages: Language[] = Object.keys(languagesData).map((name: string) => {
  const languageName = name as LanguageName;
  const language = languagesData[languageName];
  const scaleName = language.scale as ScaleName;
  return ({
    ...language,
    scale: {
      name: scaleName,
      levels: scaleData[scaleName],
    },
    name: languageName,
  })
});


export function getLanguage(languageName: string): Language {
  const language = languages.find(l => l.name === (languageName || 'Spanish'));
  return language ?? languages[0];
}

export function getLanguageName(languageCode: string): string {
  const language = languages.find(l => l.code === languageCode);
  return language?.name ?? 'English';
}

export default languages;