export const debounce = (callback: (...args: unknown[]) => void, wait: number, execute = false) => {
  let timeoutId: number | undefined;
  let hasExecuted = false;
  return (...args: unknown[]) => {
    window.clearTimeout(timeoutId);
    if (execute && !hasExecuted) {
      callback.apply(null, args);
      hasExecuted = true;
    }
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};
