import React from 'react';
import ReactDOM from 'react-dom/client';
import '@radix-ui/themes/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Theme } from '@radix-ui/themes';
import { writeError, writeLog } from './api';

window.onerror = (_a, _b, _c, _d, error) => {
  writeError('Unhandled error', error);
  return false;
}

window.onunhandledrejection = (event) => {
  writeError('Unhandled error', event.reason);
  return false;
}

writeLog('App loaded!');

if (window.matchMedia) {
  const { classList } = document.body;
  const isDark = window.matchMedia('(prefers-color-scheme: dark)');
  if (isDark.matches) {
    classList.add('dark');
  }

  isDark.addEventListener('change', event => {
    if (event.matches) {
      classList.add('dark');
    } else {
      classList.remove('dark');
    }
  });
}

const { audioSession } = (navigator as any);
if (audioSession && audioSession.type) {
  audioSession.type = 'play-and-record';
}

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Theme style={{ minHeight: '90vh', display: 'flex' }}>
        <App />
      </Theme>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
