export function FlagIcon() {
  const style = {
    height: '1.25em',
    width: '1.25em',
    marginBottom: '-0.25em',
    fill: 'currentColor',
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g>
        <g>
          <path d="m460.2,323c-23.3-7.3-47.9-10.8-74.5-10.8-45.6,0-90.7,10.5-134.3,20.7-43.3,10.1-84.1,19.7-125,19.7-27,0-51.5-4.3-74.5-13.2v-248.4c23.3,7.3 47.9,10.8 74.5,10.8 45.6,0 90.7-10.5 134.3-20.7 43.3-10.1 84.1-19.7 125-19.7 27.1,0 51.6,4.3 74.5,13.2v248.4zm29-280.2c-31.9-14.9-65.7-22.1-103.6-22.1-45.6,0-90.7,10.5-134.3,20.7-43.2,10.1-84.1,19.6-125,19.6-27.1,0-51.6-4.4-74.5-13.2v-16.4c0-11.3-9.1-20.4-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v449.2c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-98.1c23.3,7.3 47.9,10.9 74.5,10.9 45.6,0 90.7-10.5 134.3-20.7 43.3-10.1 84.2-19.7 125-19.7 31.7,0 60.4,5.1 86.3,18.3 14.3,7.3 29.1,3.3 29.1-18.5v-291.5c-5.68434e-14-8-4.6-15.2-11.8-18.5z" />
        </g>
      </g>
    </svg>
  );
}
