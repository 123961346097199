import React, { CSSProperties, useMemo } from 'react';
import { Flex, Button, IconButton } from '@radix-ui/themes';
import { EyeClosedIcon } from '../icons/EyeClosedIcon';
import './Message.css';
import { ThumbsDownIcon } from '../icons/ThumbsDownIcon';
import { HeartFilledIcon } from '@radix-ui/react-icons';
import { Tapback } from '../Conversation';
import { observer } from 'mobx-react-lite';
import { ThumbsUpIcon } from '../icons/ThumbsUpIcon';

type MessageBubbleProps = {
  position: 'sent' | 'received' | 'sent loading' | 'sent recording';
  onClick?: () => void;
  children: React.ReactNode | (React.ReactNode)[];
  bulge?: boolean;
  tapbacks?: Tapback[];
};

function ThumbsDownTapback() {
  return (
    <IconButton className="tapback" color="gray" size="2" radius="full">
      <ThumbsDownIcon />
    </IconButton>
  );
};

function ThumbsUpTapback() {
  return (
    <IconButton className="tapback" color="grass" size="2" radius="full">
      <ThumbsUpIcon />
    </IconButton>
  );
};

function HeartTapback({index}: {index: number}) {
  const seed = useMemo(Math.random, []) * 0.5;
  const percent = Math.log(index + 1);
  const top = ((Math.abs(Math.sin(index)) * -20) * seed + 5) * percent;
  const left = ((Math.cos(index)) * 10) * percent;
  const style = {
    '--tapback-top': `${top}px`,
    '--tapback-left': `${left}px`,
    '--tapback-rotate': `${left}deg`,
  } as CSSProperties;
  return (
    <IconButton style={style} className="tapback" color="red" size="2" radius="full">
      <HeartFilledIcon style={{transform: 'scale(1.25)'}} />
    </IconButton>
  );
};

const MessageBubble = React.memo(observer(React.forwardRef<HTMLDivElement, MessageBubbleProps>((props, ref) => {
  const { children, position, bulge, onClick, tapbacks: tapbacks, ...rest } = props;
  const style = bulge ? { animation: '2s bulge infinite ease-out' } : undefined;
  return (
    <Flex position="relative" className={'message ' + position} {...rest} ref={ref}>
      <Flex style={style} onClick={(e) => { onClick?.(); e.stopPropagation(); }} className={'bubble ' + (bulge ? 'bulging' : '')} mb="2" px="3" py="2">
        {children}
        {tapbacks?.map((a, i) => (
          a === 'heart' ? <HeartTapback index={i} key={i} /> : a === 'thumbsdown' ? <ThumbsDownTapback key={i} /> : a === 'thumbsup' && <ThumbsUpTapback key={i} />
        ))}
        <MessageTail />
      </Flex>
    </Flex>
  );
})));

function MessageTail() {
  return (
    <Flex className={'message-tail'}>
      <svg style={{ width: "1.5rem", height: "1rem" }} viewBox="0 0 150 100">
        <path d="M0 0 Q 0 100, 100 100 L 175 100 Q 75 100, 75 0 L 0 0" />
      </svg>
    </Flex>
  )
}

type ConcreteMessageProps = Omit<MessageBubbleProps, 'position'>;

type SentMessageProps = ConcreteMessageProps & { recording?: 'loading' | 'on' }

export const SentMessage = React.forwardRef<HTMLDivElement, SentMessageProps>((props, ref) => {
  const position = props.recording === 'loading' ? 'sent loading' : props.recording === 'on' ? 'sent recording' : 'sent';
  return (
    <MessageBubble {...props} position={position} ref={ref}>
      {props.children}
    </MessageBubble>
  );
});

export const ReceivedMessage = React.forwardRef<HTMLDivElement, ConcreteMessageProps>((props, ref) => {
  return (
    <MessageBubble {...props} position={'received'} ref={ref}>
      {props.children}
    </MessageBubble>
  );
});

type MessageOptionsProps = {
  variant?: 'soft';
  options: [string, React.ReactNode][];
  onClick: (name: string) => void;
};

export const MessageOptions = (({ variant, options, onClick }: MessageOptionsProps) => {
  return options.length ? (
    <Flex gap="2" mt="2" mb="1">
      {options.map(([name, icon]) => (
        <Button radius="large" key={name} variant={variant} onClick={() => onClick(name)}>
          {icon} {name}
        </Button>
      ))}
    </Flex>
  ) : null;
});

type HiddenMessageProps = {
  children: React.ReactNode | (React.ReactNode)[];
  hide: boolean;
};

export const HiddenMessage = ({ hide, children }: HiddenMessageProps) => {
  return (
    <Flex position="relative">
      {hide && (
        <Flex className="hidden-bubble">
          <EyeClosedIcon />
        </Flex>
      )}
      <Flex direction="column" style={{ color: hide ? 'transparent' : undefined }}>
        {children}
      </Flex>
    </Flex>
  );
};

