type RecordIconProps = {
  width?: string;
  height?: string;
  marginBottom?: string;
  muted?: boolean;
}

export function RecordIcon(props: RecordIconProps) {
  const { width = null, height = null, marginBottom = null, muted = false } = props;
  const style = {
    width: width ?? '1.5em',
    height: height ?? '1.5em',
    marginBottom: marginBottom ?? undefined,
    fill: 'currentColor'
  };
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 512 512">
      <defs>
        <mask id="transparentMask">
          <rect width="100%" height="100%" fill="white" />
          {muted && <rect id="mask" x="calc(50% - 1.5rem)" y="0" style={{ transform: 'rotate(0.15turn) translateX(-3rem)', transformOrigin: '50%' }} width="3rem" height="100%" fill="black" />}
        </mask>
      </defs>
      <g mask="url(#transparentMask)">
        <path d="m439.5,236c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,70-64,126.9-142.7,126.9-78.7,0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,86.2 71.5,157.4 163.1,166.7v57.5h-23.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h88c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
        <path d="m256,323.5c51,0 92.3-41.3 92.3-92.3v-127.9c0-51-41.3-92.3-92.3-92.3s-92.3,41.3-92.3,92.3v127.9c0,51 41.3,92.3 92.3,92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3,23.5 52.3,52.3v127.9c0,28.8-23.5,52.3-52.3,52.3s-52.3-23.5-52.3-52.3v-127.9z" />
        {muted && <rect x="calc(50% - 1.5rem)" y="0" style={{ transform: 'rotate(0.15turn)', transformOrigin: '50%' }} width="3rem" height="100%" />}
      </g>
    </svg>
  );
}
