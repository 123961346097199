import fallbackAudioUrl from './audio/fallback.mp3';
import { LanguageCode } from './languages';

const VITE_API_URL = import.meta.env.VITE_APP_API_URL ?? '';
const API_URL = VITE_API_URL?.indexOf('8000') > -1 ? VITE_API_URL.replace('localhost', window.location.hostname) : VITE_API_URL;

const FILE_EXT_REGEX = /audio\/(\w+)/;

export function getWords(content: string, code: LanguageCode) {
  const segmenter = new Intl.Segmenter(code, { granularity: 'word' });
  const iterator = segmenter.segment(content)[Symbol.iterator]();
  let words = [];
  for (const segment of iterator) {
    if (segment.isWordLike) {
      words.push(segment.segment.toLowerCase());
    }
  }
  return words;
}

export async function fetchLogs() {
  const result = await fetch(`${API_URL}/event`);
  return result.json();
}

const STUDYBUDDY_ID = 'StudyBuddyID';

function getId() {
  let id = localStorage.getItem(STUDYBUDDY_ID);
  if (!id) {
    const foundId = id = crypto.randomUUID ? crypto.randomUUID() : String(Math.random());
    localStorage.setItem(STUDYBUDDY_ID, id);
    writeEvent(id, {
      event: ['USER_INFO', {
        browser: navigator.userAgent,
        language: navigator.language
      }]
    });
    getLocation().then((location: LocationData) => {
      writeLog(foundId, ['LOCATION', location]);
    })
  }
  return id;
}

interface LocationData {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  asn: string;
  org: string;
}

async function getLocation() {
  try {
    const res = await fetch(`https://ipapi.co/json/`);
    return await res.json();
  } catch (e) {
    writeError('Failed to fetch IP info', e);
  }
}

export function writeLog(...event: any[]) {
  let id = getId();
  console.log(...event);
  return writeEvent(id, { event });
}

export function writeError(...error: any[]) {
  let id = getId();
  console.error(...error);
  const event = error.map(e => (
    e instanceof Error ? { stack: e.stack, name: e.name, message: e.message } : e)
  );
  return writeEvent(id, { error: true, event }).catch(() => {});
}

function writeEvent(id: string, event: { error?: true, event: any[] }) {
  return fetch(`${API_URL}/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id, ...event, time: (new Date()).toISOString() }),
  });
}

export async function transcribe(audio: Blob, languageCode?: LanguageCode): Promise<{ message: string; }> {
  let id = getId();
  const formData = new FormData();
  const filename = 'audio.' + audio.type.match(FILE_EXT_REGEX)?.[1];

  formData.append('audio', audio, filename);

  return fetch(`${API_URL}/transcriptions?language=${languageCode ?? 'en'}&id=${id}`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
    .catch((error) => {
      writeError('Failed to transcribe: ', error);
      return { message: 'Failed to transcribe.' };
    });
}

const spoken: { [message: string]: string } = {};

export async function speak(message: string, languageCode: LanguageCode, times = 0): Promise<string> {
  if (spoken[message]) {
    return spoken[message];
  }

  let id = getId();
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id, message, retry: times > 0 }),
  };
  return fetch(`${API_URL}/speech`, request)
    .then(async res => res.blob())
    .then(async blob => {
      if (languageCode === 'en') {
        spoken[message] = URL.createObjectURL(blob);
        return spoken[message];
      }
      const res = await transcribe(blob, languageCode);
      const generated = getWords(res.message, languageCode).join(' ');
      const original = getWords(message, languageCode).join(' ');

      if (generated === original || times > 3) {
        if (times > 3) {
          writeError(`Failed after 3 attempts to say: ${message} (${languageCode})`);
        }
        spoken[message] = URL.createObjectURL(blob);
        return spoken[message];
      }
      
      return speak(message, languageCode, times + 1);
    })
    .catch((e) => {
      writeError('Failed to generate audio: ', e);

      if (import.meta.env.DEV) {
        return fallbackAudioUrl;
      }
      return ''
    });
}

export async function translate(input: string): Promise<string> {
  try {
    const message = `Translate the following expression into English: ${input}`;
    const response = await completions([{
      role: 'user',
      content: message,
    }]);
    return response.content;
  } catch (e) {
    writeError('Failed to translate: ', e);
    return 'Failed to translate.';
  }
}

type CompletionMessage = {
  role: 'user' | 'system' | 'assistant';
  content: string;
};

export type Message = {
  role: 'user' | 'system' | 'assistant';
  content: string;
};

export async function completions(messages: CompletionMessage[]): Promise<AssistantMessage> {
  let id = getId();
  return fetch(`${API_URL}/completions`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({id, messages})
  }).then(async (res) => {
    const { role, content } = await res.json();
    if (!res.ok) {
      throw new Error(content);
    }
    return { role, content };
  });
}

export type AssistantMessage = Message & {
  role: 'assistant';
};

export async function generate(messages: CompletionMessage[]): Promise<AssistantMessage> {
  try {
    const { role, content } = await completions(messages);
    return {
      role,
      content,
    }
  } catch (e) {
    writeError('Failed to generate completion:', e);

    if (import.meta.env.DEV) {
      return {
        role: 'assistant',
        content: 'Hola, soy Buddy, tu tutor de español.',
      };
    }
  
    return {
      role: 'assistant',
      content: 'I am having connection issues!',
    };
  }
}
