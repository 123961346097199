import { Button, DropdownMenu } from '@radix-ui/themes';
import { CaretDownIcon, CheckIcon } from '@radix-ui/react-icons';
import type { Language } from '../languages';
import languages from '../languages';
import { FlagIcon } from '../icons/FlagIcon';

export type LanguagePickerProps = {
  value: Language;
  onChange: (change: Language) => void;
};

export function LanguagePicker({ value, onChange }: LanguagePickerProps) {
  const high = languages.filter(a => a.error < 0.3);
  const modHigh = languages.filter(a => a.error >= 0.3 && a.error <= 0.45);
  const moderate = languages.filter(a => a.error > 0.45);

  const toItem = (language: Language) => (
    <DropdownMenu.Item
      key={language.name}
      onSelect={() => onChange(language)}
      disabled={language === value}
    >
      <span>
        {language === value && (
          <CheckIcon width="20" height="20" style={{
            height: '1.25em',
            width: '1.25em',
            marginBottom: '-0.25em'
          }} />
        )}
        {language.name}
      </span>
    </DropdownMenu.Item>
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant="soft">
          <FlagIcon /> {value.name}
          <CaretDownIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Group>
          <DropdownMenu.Label>High proficiency</DropdownMenu.Label>
          {high.map(toItem)}
        </DropdownMenu.Group>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          <DropdownMenu.Label>Moderate to high proficiency</DropdownMenu.Label>
          {modHigh.map(toItem)}
        </DropdownMenu.Group>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          <DropdownMenu.Label>Moderate proficiency</DropdownMenu.Label>
          {moderate.map(toItem)}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
