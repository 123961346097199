import { useState } from 'react';
import { Flex, Button, Dialog, IconButton, Card } from '@radix-ui/themes';
import { GearIcon } from '@radix-ui/react-icons';
import type { LanguagePickerProps } from './LanguagePicker';
import { LanguagePicker } from './LanguagePicker';
import './Settings.css';

export type SettingsProps = {
  defaultOpen: boolean;
  onDone: () => Promise<void>;
  doneText: 'Start' | 'Continue';
  language: LanguagePickerProps['value'];
  onLanguageChange: LanguagePickerProps['onChange'];
};

// TODO: Add refresh button to the options
export function Settings({ onDone, language, onLanguageChange, defaultOpen, doneText }: SettingsProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const onDoneClick = async () => {
    await onDone();
    setIsOpen(false);
  };

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger>
        <IconButton variant='ghost' color="gray" size="4" radius="full">
          <GearIcon onClick={() => setIsOpen(true)} width="22" height="22" />
        </IconButton>
      </Dialog.Trigger>

      <Dialog.Content style={{ maxWidth: 450 }} aria-describedby='Select a language'>
        <Dialog.Title>
          <GearIcon width="22" height="22" style={{ marginBottom: '-0.25rem' }} /> Choose a language
        </Dialog.Title>
        <Flex direction="column" gap="4">
          <Card>
            <LanguagePicker value={language} onChange={onLanguageChange} />
          </Card>
        </Flex>
        <Dialog.Description></Dialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button onClick={onDoneClick}>{doneText}</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
