/// <reference path="global.d.ts" />
import { completions, generate, getWords, speak, transcribe, translate, writeError, writeLog } from "./api";
import { Language, LanguageCode, getLanguageName } from "./languages";
import { action, makeAutoObservable, reaction, runInAction } from 'mobx';
import tapbackAudio from './audio/tapback.mp3';
// import wowAudio from './audio/wow.mp3';
import sentAudio from './audio/sentmessage.mp3';
import loveAudio from './audio/love.mp3';
import playAudio from './audio/lets-play.mp3';
import rightAudio from './audio/correct.mp3';
import wrongAudio from './audio/wrong.mp3';
import finalAudio from './audio/final.mp3';
import finaleAudio from './audio/finale.mp3';
import { audio, wait } from "./audio/audio";

type TypingState = 'idle' | 'typing';

// function randomItem<T>(items: T[]): T { // min and max included 
//   return items[Math.floor(Math.random() * items.length)]
// }

// const WORD = /[\p{Letter}\p{Mark}]+/gu;

async function pronounce(expression: string): Promise<string> {
  const pronouncePrompt = `The expression "${expression}", in English syllables, is pronounced: `;
  const pronounceMessage: Message = { role: 'user', content: pronouncePrompt };
  const pronounce = await completions([pronounceMessage]);
  return pronounce.content;
}

async function breakdown(languageCode: LanguageCode, expression: string): Promise<string> {
  const language = getLanguageName(languageCode);
  const breakdownPrompt = `Explain the following ${language} expression's parts by grammar and structure rules in less than 50 words: ${expression}`;
  const breakdown = await completions([{ role: 'user', content: breakdownPrompt }]);
  return breakdown.content;
}

async function fetchAttachment(languageCode: LanguageCode, expression: string): Promise<AttachmentItem[]> {
  return [
    { name: 'Expression', value: expression },
    { name: 'Pronunciation', value: await pronounce(expression) },
    { name: 'Translation', value: await translate(expression) },
    { name: 'Description', value: await breakdown(languageCode, expression) },
    { name: 'Audio', value: expression },
  ];
}

export interface AttachmentItem {
  name: string;
  value: string;
};

export type Link = FailureLink | OverviewLink;

interface FailureLink {
  name: string;
  type: 'failure',
  reason: AttachmentItem;
};

interface OverviewLink {
  name: string;
  type: 'overview',
};

const SOUNDS: Record<NonNullable<Sounds>, string> = {
  play: playAudio,
  right: rightAudio,
  wrong: wrongAudio,
  final: finalAudio,
  finale: finaleAudio,
  sent: sentAudio,
}

export type Message = {
  role: 'user' | 'system' | 'assistant';
  content: string;
};

export type Tapback = 'heart' | 'thumbsdown' | 'thumbsup';

function checkForKey(key: string, obj: { [key: string]: unknown }) {
  if (obj[key] == null) {
    writeError(`Missing key: ${key}`);
  }
}

type Sounds = 'play' | 'right' | 'wrong' | 'final' | 'finale' | 'sent';

class AssistantMessage {
  playCount = 0;
  role: 'assistant' = 'assistant';
  rate = 1;
  languageCode: LanguageCode = 'en';
  size?: "5" | "7" | "9";
  hide: boolean;
  sound?: Sounds;
  links?: Link[];
  loaded?: Promise<void>;
  content: string;
  silent = false;

  constructor(value: Partial<AssistantMessage>) {
    makeAutoObservable(this);
    this.hide = value.hide ?? ((value?.languageCode ?? 'en') !== 'en');
    checkForKey('content', value);
    this.content = value.content!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [this];
  }

  async play(): Promise<void> {
    if (this.silent) {
      return;
    }

    const audioUrl = this.sound ? SOUNDS[this.sound] : await speak(this.content, this.languageCode);
    runInAction(() => { this.playCount = 1; });

    if (this.sound) {
      await audio.play(audioUrl, { duration: 0.4, cache: true })
    } else {
      const rate = this.role === 'assistant' ? this.rate : 1;
      await audio.play(audioUrl, { rate });
      await wait(100);
    }
  }
}

class UserMessage {
  id = crypto.randomUUID ? crypto.randomUUID() : String(Math.random());
  role: 'user' = 'user';
  playCount = 0;
  content: string;

  constructor(
    public getLevel: () => Level,
    value: Partial<UserMessage>,
  ) {
    makeAutoObservable(this);
    checkForKey('content', value);
    this.content = value.content!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [this];
  }

  get tapbacks(): Tapback[] {
    const level = this.getLevel();
    return level._messages
      .flatMap((a) => a.messages)
      .filter((a): a is TapbackMessage => a.role === 'tapback' && a.userMessageId === this.id)
      .map(a => a.type);
  }

  async play(): Promise<void> {
    runInAction(() => { this.playCount = 1; });
    await audio.play(sentAudio, { cache: true, duration: 0.35 });
  }
}

class TapbackMessage {
  role: 'tapback' = 'tapback';
  userMessageId: string;
  type: Tapback;
  playCount = 0;

  constructor(value: Partial<TapbackMessage>) {
    makeAutoObservable(this);
    checkForKey('userMessageId', value);
    checkForKey('type', value);
    this.userMessageId = value.userMessageId!;
    this.type = value.type!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [this];
  }

  async play(): Promise<void> {
    runInAction(() => { this.playCount = 1; });
    if (this.type === 'heart') {
      // if (hearts > 0 && hearts % 5 === 0) {
      //   // this.set({ shock: true });
      //   // setTimeout(() => this.set({ shock: false }), 1000);
      //   await audio.play(wowAudio, { duration: 0.1, cache: true });
      // }
      await audio.play(loveAudio, { duration: 0.25, cache: true });
    } else {
      await audio.play(tapbackAudio, { duration: 0.25, cache: true });
    }
  }
}

class FutureMessage {
  role: 'promise' = 'promise';
  constructor(public promise: Promise<unknown>) {}
}

export type PromptMessage = UserMessage | AssistantMessage;
type ViewMessage = PromptMessage | FutureMessage | TapbackMessage;

class IntroMessage {
  role: 'intro' = 'intro';
  playCount = 0;
  responseType: 'en' = 'en';
  loaded?: Promise<void>;
  suggestions?: string;

  constructor(
    public getLevel: () => Level,
    value?: Partial<IntroMessage>
  ) {
    makeAutoObservable(this);
    Object.assign(this, value);
    const level = getLevel();
    const conversation = level.getConversation();
    const index = conversation.levels.indexOf(level);
    setTimeout(() => {
      const scenarios = conversation.levels.slice(0, index)
        .flatMap(a => a._messages).filter(
          (a): a is ExpressionsMessage => a.role === 'expressions'
        ).map(a => a.content);

      if (scenarios.length && !this.suggestions) {
        runInAction(() => {
          this.loaded = this.generateSuggestions(scenarios);
        })
      }
    })
  }

  async generateSuggestions(scenarios: string[]) {
    const message = `List 3 conflict scenarios, each one concise sentence, relating to the following scenarios:\nSCENARIO: ${scenarios.join('\nSCENARIO: ')}\nSCENARIO: `;
    const scenarioRes = (await generate([{ role: 'system', content: message }]));
    runInAction(() => {
      this.suggestions = scenarioRes.content.split('\n').map(a => a.replace('SCENARIO: ', '')).join('\n');
    })
  }

  async next(userMessage: UserMessage): Promise<MessageEvent[]> {
    const { content } = userMessage;
    const level = this.getLevel();
    const conversation = level.getConversation();
    const previous: Message[] = conversation.levels
      .flatMap(a => a._messages).filter(
        (a): a is ExpressionsMessage => a.role === 'expressions'
      ).flatMap(a => [{ role: 'user', content: `The situation is:\n${a.content}\nYOU: ` }, { role: 'assistant', content: a.dialog }]);

    const language = getLanguageName(level.languageCode);
    const message = `Write 4 explicit lines of dialog between YOU and ME in ${language} given a situation. Each line no more than 4 words, no translations, production ready.`;
    const dialog = (await generate([{ role: 'system', content: message }, ...previous, { role: 'user', content: `The situation is:\n${content}\nYOU: ` }])).content;
    return [
      new ExpressionsMessage(this.getLevel, { content, dialog }),
      // new WelcomeMessage(this.getLevel),
      new RepeatMessage(this.getLevel, { index: 0 }),
    ];
  }

  get messages(): ViewMessage[] {
    const level = this.getLevel();
    const language = getLanguageName(level.languageCode);

    const intro = new AssistantMessage({
      content: `Describe a situation where you'd like to speak ${language}.`
    });

    if (this.suggestions) {
      return [intro, new AssistantMessage({ content: `For example:\n${this.suggestions}`, silent: true })];
    } else if (this.loaded) {
      return [intro, new FutureMessage(this.loaded)];
    } else {
      return [intro];
    }
  }
}

class ExpressionsMessage {
  role: 'expressions' = 'expressions';
  playCount = 0;
  responseType: 'en' = 'en';
  dialog: string;
  content: string;
  loaded?: Promise<void>;
  translated?: string;

  constructor(
    public getLevel: () => Level,
    value: Partial<ExpressionsMessage>
  ) {
    makeAutoObservable(this);
    checkForKey('content', value);
    checkForKey('dialog', value);
    this.content = value.content!;
    this.dialog = value.dialog!;
    Object.assign(this, value);
    if (!this.translated) {
      this.loadTranslation()
    }
  }

  async loadTranslation() {
    const translated = await translate('YOU: ' + this.dialog);
    runInAction(() => {
      this.translated = translated;
    });
  }

  get expressions() {
    return this.dialog?.split('\n').map(a => a.replace('YOU: ', '').replace('ME: ', ''));
  }

  get messages(): ViewMessage[] {
    return [
      new AssistantMessage({ content: 'Here is the dialog we\'ll practice' }),
      this.translated ?
        new AssistantMessage({
          content: this.translated.split('\n').map(
            a => a.replace('YOU: ', 'Me: ').replace('ME: ', 'You: ')
          ).join('\n'),
          silent: true
        }) :
        new FutureMessage(this.loaded!),
    ]
  }
}

// class WelcomeMessage {
//   role: 'welcome' = 'welcome';
//   playCount = 0;
//   responseType: 'en' = 'en';
//   loaded?: Promise<void>;

//   constructor(
//     public getLevel: () => Level,
//     value?: Partial<WelcomeMessage>
//   ) {
//     makeAutoObservable(this);
//     Object.assign(this, value);
//   }

//   get messages(): ViewMessage[] {
//     const { level } = this.getLevel();

//     const levelNames = [
//       'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
//     ];

//     return [
//       new AssistantMessage({
//         content: `🛡️ Welcome to level ${levelNames[level - 1]} 🛡️`,
//         size: '5',
//         sound: 'play',
//       }),
//     ];
//   }
// }

class TranslateFailMessage {
  role: 'translate_fail' = 'translate_fail';
  index: number;
  userMessageId: string;
  translation: string | null = null;
  loaded?: Promise<unknown>;
  responseType: 'en' = 'en';
  playCount = 0;
  now = (new Date()).toISOString();

  constructor(
    public getLevel: () => Level,
    value: Partial<TranslateFailMessage>
  ) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    checkForKey('userMessageId', value);
    this.userMessageId = value.userMessageId!;
    Object.assign(this, value);
    if (!value.translation) {
      this.loaded = this.loadTranslation();
    }
  }

  private async loadTranslation() {
    const expression = this.getLevel().expressions[this.index];
    const translation = await translate(expression);
    runInAction(() => { this.translation = translation; });
  }

  get messages(): ViewMessage[] {
    return [
      new TapbackMessage({ type: 'thumbsdown', userMessageId: this.userMessageId }),
      new AssistantMessage({ content: `The correct meaning is:` }),
      this.translation ?
        new AssistantMessage({ content: this.translation }) :
        new FutureMessage(this.loaded!),
    ];
  }
}


class TranslateSuccessMessage {
  role: 'translate_success' = 'translate_success';
  index: number;
  userMessageId: string;
  responseType: 'en' = 'en';
  playCount = 0;
  now = (new Date()).toISOString();

  constructor(value: Partial<TranslateSuccessMessage>) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    checkForKey('userMessageId', value);
    this.userMessageId = value.userMessageId!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [new TapbackMessage({ type: 'thumbsup', userMessageId: this.userMessageId })];
  }
}

class TranslateMessage {
  role: 'translate' = 'translate';
  playCount = 0;
  responseType: 'en' = 'en';
  index: number;
  loaded?: Promise<void>;

  constructor(
    public getLevel: () => Level,
    public value: Partial<TranslateMessage>
  ) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [new AssistantMessage({
      content: `What does it mean?`,
    })];
  }

  async next(userMessage: UserMessage): Promise<MessageEvent[]> {
    const level = this.getLevel();

    const content = level.expressions[this.index];

    const results: MessageEvent[] = [];

    const rateTranslation = `You're a tutor asked if "${content}" translates to "${userMessage.content}"? Answer 'YES' or 'NO': '`;
    const ratingRes = await generate([{ role: 'user', content: rateTranslation }]);
    const rating = ratingRes.content.toUpperCase();
    const userWords = getWords(userMessage.content, 'en').join(' ');
    const words = getWords(content, 'en').join(' ');

    // Next index...
    const index = this.index + 1;

    if (!rating.startsWith('YES') && !userWords.includes(words)) {
      results.push(new TranslateFailMessage(this.getLevel, { index: this.index, userMessageId: userMessage.id }));

      if (level.currentMessages.filter((a) => a.role === 'translate_fail').length) {
        results.push(new FailureMessage(), new RepeatMessage(this.getLevel, { index: 0 }));
      } else if (index === level.totalChallenges) {
        results.push(new FinaleMessage(this.getLevel));
      } else {
        results.push(new RepeatMessage(this.getLevel, { index }));
      }
    } else {
      results.push(new TranslateSuccessMessage({ index: this.index, userMessageId: userMessage.id }));

      if (index === level.totalChallenges) {
        results.push(new FinaleMessage(this.getLevel));
      } else {
        results.push(new RepeatMessage(this.getLevel, { index }));
      }
    }

    return results;
  }
}

class RepeatFailMessage {
  role: 'repeat_fail' = 'repeat_fail';
  index: number;
  userMessageId: string;
  translation: string | null = null;
  responseType: 'en' = 'en';
  loaded?: Promise<void>;
  playCount = 0;
  failureHelpers: Partial<AssistantMessage>[] = [];
  now = (new Date()).toISOString();

  get attempts() {
    return this.getLevel().currentMessages.filter(
      a => a.role === 'repeat_fail' && a.index === this.index
    ).length    
  }

  constructor(
    public getLevel: () => Level,
    value: Partial<RepeatFailMessage>
  ) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    checkForKey('userMessageId', value);
    this.userMessageId = value.userMessageId!;
    delete (value as any).attempts;
    Object.assign(this, value);

    if (this.failureHelpers.length === 0) {
      this.loaded = this.loadFailures();
    }
  }

  get messages(): ViewMessage[] {
    const result: ViewMessage[] = [
      new TapbackMessage({ type: 'thumbsdown', userMessageId: this.userMessageId })
    ]

    return result.concat(
      (this.failureHelpers.length === 0) ?
        new FutureMessage(this.loaded!) :
        this.failureHelpers.map(h => new AssistantMessage(h))
    )
  }

  async loadFailures() {
    const result = await this.fail(this.attempts % 2);
    runInAction(() => {
      this.failureHelpers.push(...result);
    });
  }

  private async fail(failIndex: number) {
    const level = this.getLevel();
    const content = level.expressions[this.index];
    switch (failIndex) {
      case 0:
        return this.failHelperOne(content);
      case 1:
        return this.failHelperTwo(content);
      default:
        return this.failHelperThree(content);
    }
  }

  private async failHelperThree(content: string) {
    const level = this.getLevel();
    return [{
      content: content,
      languageCode: level.languageCode,
      rate: 0.5,
    }];
  }

  private async failHelperTwo(content: string) {
    const level = this.getLevel();
    return [{
      content: `Listen carefully.`,
    }, {
      content: content,
      languageCode: level.languageCode,
      rate: 0.5,
    }];
  }

  private async failHelperOne(content: string) {
    const level = this.getLevel();
    return [{
      content: `Pronunciation tip: ${await pronounce(content)}`,
      silent: true,
    }, {
      content: `Try again, please.`,
    }, {
      content: content,
      languageCode: level.languageCode,
    }];
  }
}


class RepeatSuccessMessage {
  role: 'repeat_success' = 'repeat_success';
  index: number;
  userMessageId: string;
  responseType: 'en' = 'en';
  playCount = 0;
  now = (new Date()).toISOString();

  constructor(value: Partial<RepeatSuccessMessage>) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    checkForKey('userMessageId', value);
    this.userMessageId = value.userMessageId!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [new TapbackMessage({ type: 'thumbsup', userMessageId: this.userMessageId })];
  }
}

class RepeatMessage {
  role: 'repeat' = 'repeat';
  playCount = 0;
  index: number;
  loaded?: Promise<void>;

  get responseType(): LanguageCode {
    return this.getLevel().languageCode;
  }

  constructor(
    public getLevel: () => Level,
    public value: Partial<RepeatMessage>
  ) {
    makeAutoObservable(this);
    checkForKey('index', value);
    this.index = value.index!;
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    const { expressions, languageCode } = this.getLevel();
    const challengeNames = ['First', 'Second', 'Third', 'Forth', 'Final'];
    const expression = expressions[this.index];
    const results: AssistantMessage[] = [];

    if (this.index === 0) {
      const first = new AssistantMessage({
        content: `🏔️ ${challengeNames[this.index]} challenge 🏔️`,
        size: '5',
        sound: 'final',
      });

      const repeat = new AssistantMessage({
        content: `Repeat the following`,
      });

      results.push(first, repeat);
    }

    results.push(new AssistantMessage({
      content: expression,
      languageCode,
    }));

    return results;
  }

  async next(userMessage: UserMessage): Promise<MessageEvent[]> {
    const level = this.getLevel();
    const content = level.expressions[this.index];

    const original = getWords(content, level.languageCode).join(' ');
    const spoken = getWords(userMessage.content, level.languageCode).join(' ');

    writeLog({ original, spoken });

    if (!spoken.includes(original)) {
      return [
        new RepeatFailMessage(this.getLevel, {
          index: this.index,
          userMessageId: userMessage.id
        })
      ];
    } else {
      return [
        new RepeatSuccessMessage({
          index: this.index,
          userMessageId: userMessage.id
        }),
        new TranslateMessage(this.getLevel, {
          index: this.index,
        })
      ];
    }
  }
}

class FailureMessage {
  playCount = 0;
  role: 'failure' = 'failure';
  responseType: 'en' = 'en';
  evaluation?: string;
  loaded?: Promise<void>;

  constructor(value?: Partial<FailureMessage>) {
    makeAutoObservable(this);
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    const failure: 'failure' = 'failure';
    const links = this.evaluation ? [{
      name: 'Failure reason',
      type: failure,
      reason: { // TODO: <-- Add some kind of link type since we can't serialize the function
        name: 'Explanation',
        value: this.evaluation
      }
    }] : undefined;

    return [
      new AssistantMessage({
        content: '😭 Failed 😭',
        sound: 'wrong',
        size: '5',
        links,
      }), new AssistantMessage({
        content: 'Let\'s try again.',
      })
    ];
  }
}

class SuccessMessage {
  playCount = 0;
  loaded?: Promise<void>;
  role: 'success' = 'success';
  responseType: 'en' = 'en';

  constructor(value?: Partial<SuccessMessage>) {
    makeAutoObservable(this);
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    return [
      new AssistantMessage({
        content: 'Great job!',
      }),
      new AssistantMessage({
        content: '🏆🏆🏆',
        sound: 'right',
        size: '9',
      })
    ];
  }
}

class FinaleMessage {
  playCount = 0;
  role: 'finale' = 'finale';
  initial = true;
  index = 0;

  get responseType(): LanguageCode {
    return this.getLevel().languageCode;
  }

  constructor(
    public getLevel: () => Level,
    value?: Partial<FinaleMessage>
  ) {
    makeAutoObservable(this);
    Object.assign(this, value);
  }

  get messages(): ViewMessage[] {
    const level = this.getLevel();
    const intro: ViewMessage[] = [
      new AssistantMessage({
        content: `🔥 The ultimate test 🔥`,
        size: '5',
        sound: 'finale',
      }),
      new AssistantMessage({
        content: `Let's try using what you've learned in conversation.`,
      }),
    ];

    const message = new AssistantMessage({
      content: level.expressions[this.index],
      languageCode: level.languageCode
    });

    return this.initial ? intro.concat(message) : [message];
  }

  async next(userMessage: UserMessage): Promise<MessageEvent[]> {
    const level = this.getLevel();

    const startIndex = level.currentMessages.findIndex((m) => m.role === 'finale')
    const finaleMessages = level.currentMessages.slice(startIndex) as (FinaleMessage | UserMessage)[];

    const content = level.expressions[this.index + 1];

    const original = getWords(content, level.languageCode).join(' ');
    const spoken = getWords(userMessage.content, level.languageCode).join(' ');

    writeLog({ original, spoken });

    if (!spoken.includes(original)) {
      return [
        new FailureMessage({ evaluation: `Expected "${content}"` }),
        new RepeatMessage(this.getLevel, { index: 0 }),
      ]
    }

    if (finaleMessages.length === level.expressions.length) {
      return [
        new SuccessMessage(),
        new IntroMessage(this.getLevel),
      ];
    }

    return [new FinaleMessage(this.getLevel, {
      index: this.index + 2,
      initial: false,
    })];
  }
}

async function playEvent(event: MessageEvent): Promise<void> {
  if (event.role === 'user') {
    return event.play();
  }
  while(event.playCount < event.messages.length) {
    const message = event.messages[event.playCount];
    if (message.role === 'promise') {
      await message.promise;
    } else {
      runInAction(() => { event.playCount += 1 });
      await message?.play();
    }
  }
}

function renderEvent(event: MessageEvent): PromptMessage[] {
  const { messages } = event;
  const result = event.role === 'user' ? messages : messages.slice(0, event.playCount);
  return result.filter((m): m is PromptMessage => m.role !== 'promise' && m.role !== 'tapback');
}

type ResponseMessage = FinaleMessage | RepeatMessage | TranslateMessage;
type ResultMessage = TranslateFailMessage | TranslateSuccessMessage | RepeatFailMessage | RepeatSuccessMessage | ExpressionsMessage;
type BuddyMessage = FailureMessage | SuccessMessage | ResponseMessage | ResultMessage | IntroMessage;
type MessageEvent = UserMessage | BuddyMessage;

const deserialize = (level: Level) => (m: MessageEvent): MessageEvent | null => {
  switch (m.role) {
    case 'success':
      return new SuccessMessage(m);
    case 'failure':
      return new FailureMessage(m);
    case 'finale':
      return new FinaleMessage(() => level, m);
    case 'user':
      return new UserMessage(() => level, m);
    // case 'welcome':
    //   return new WelcomeMessage(() => level, m);
    case 'translate':
      return new TranslateMessage(() => level, m);
    case 'repeat':
      return new RepeatMessage(() => level, m);
    case 'translate_fail':
      return new TranslateFailMessage(() => level, m);
    case 'translate_success':
      return new TranslateSuccessMessage(m);
    case 'repeat_fail':
      return new RepeatFailMessage(() => level, m);
    case 'repeat_success':
      return new RepeatSuccessMessage(m);
    case 'intro':
      return new IntroMessage(() => level, m);
    case 'expressions':
      return new ExpressionsMessage(() => level, m);
    default:
      const exhaustiveCheck: never = m;
      writeError('unable to deserialize', exhaustiveCheck);
      return null;
  }
}

const getPreviousEvent = (messages: MessageEvent[]) => {
  const roles: MessageEvent['role'][] = ['finale', 'repeat', 'translate', 'intro'];
  return messages.findLast((a): a is ResponseMessage => roles.includes(a.role));
}

class Level {
  _messages: MessageEvent[] = [];
  languageCode: LanguageCode;
  level: number;

  constructor(
    public getConversation: () => Conversation,
    value: Partial<Level>
  ) {
    makeAutoObservable(this);

    checkForKey('languageCode', value);
    checkForKey('level', value);
    this.languageCode = value.languageCode!;
    this.level = value.level!;

    this.applyQueueSilently();
  }

  applyQueueSilently(): void {
    this._messages.forEach(action(a => {
      a.playCount = a.messages.length;
    }));
  }

  get learnedWords(): string[] {
    return getWords(this.expressions.filter(
      (_, i) => this._messages.find(m => m.role === 'success' || (m.role === 'translate_success' && m.index === i))
    ).join(' '), this.languageCode);
  }

  get currentChallenge(): TranslateMessage | RepeatMessage | FinaleMessage | undefined {
    return this._messages.findLast(
      (a): a is TranslateMessage | RepeatMessage | FinaleMessage =>
        a.role === 'translate' || a.role === 'repeat' || a.role === 'finale'
    );
  }

  get currentChallengeIndex(): number {
    const { index, role } = this.currentChallenge ?? {};
    return index != null ? (role === 'finale' ? index / 2 : index) : -1;
  }

  get totalChallenges() {
    return this.currentChallenge?.role === 'finale' ? this.expressions.length / 2 : this.expressions.length
  };

  get expressions() {
    return this._messages.find(
      (a): a is ExpressionsMessage => a.role === 'expressions'
    )?.expressions ?? [];
  }

  get messages(): PromptMessage[] {
    return this._messages.flatMap(renderEvent);
  }

  get currentMessages(): MessageEvent[] {
    const firstIndex = this._messages.findLastIndex(m => m.role === 'failure' || m.role === 'success') ?? -1;
    return this._messages.slice(firstIndex + 1);
  }

  get hasUnplayed() {
    return this.unplayedMessages.length > 0;
  }

  get unplayedMessages() {
    return this._messages.filter(a => a.playCount !== a.messages.length);
  }

  get success(): boolean {
    return !!this._messages.find(m => m.role === 'success');
  }

  set(value: Partial<Level>): Level {
    return Object.assign(this, value);
  }

  async load(): Promise<void> {
    if (this._messages.length === 0) {
      this.addMessage(new IntroMessage(() => this))
    }
  }

  get previousEvent() {
    return getPreviousEvent(this._messages);
  }

  async continue(file: File): Promise<void> {
    const responseType = this.previousEvent?.responseType ?? 'en'
    const language = ['start', 'continue'].includes(responseType) ? 'en' : responseType;
    const message = (await transcribe(file, language)).message

    if (!message.trim()) {
      return;
    }

    const userMessage = new UserMessage(() => this, { content: message });
    audio.recordedAudio[userMessage.id] = file;

    await this.addMessage(userMessage);
    await this.addMessage(...await this.previousEvent?.next(userMessage) ?? []);
  }

  async retry(message: PromptMessage) {
    if (message.role === 'user') {
      const index = this._messages.indexOf(message);
      if (index !== -1) {
        runInAction(() => {
          this._messages.splice(index);
        })
        const file = audio.recordedAudio[message.id];
        if (file) {
          await this.continue(file);
        } else {
          const userMessage = new UserMessage(() => this, { content: message.content });
          await this.addMessage(userMessage);
          await this.addMessage(...await this.previousEvent?.next(userMessage) ?? []);
        }
      }
    } else {
      const outputIndex = this.messages.indexOf(message);
      const userMessage = this.messages.slice(0, outputIndex)
        .findLast((a): a is UserMessage => a.role === 'user');
      this.applyQueueSilently();
      if (userMessage) {
        const index = this._messages.indexOf(userMessage);
        const previousEvent = getPreviousEvent(this._messages.slice(0, index));
        // const previousEvent = getPre;
        runInAction(() => {
          this._messages.splice(index + 1);
        });
        await this.addMessage(...await previousEvent?.next(userMessage) ?? []);
      } else {
        runInAction(() => {
          this._messages.splice(0, this._messages.length);
        })
        this.load()
      }

    }
  }

  get isFinale(): boolean {
    return !!this.currentMessages.find((a) => a.role === 'finale');
  }

  async getAttachments(link: Link): Promise<AttachmentItem[]> {
    switch (link.type) {
      case 'failure':
        return [link.reason];
      case 'overview':
        return fetchAttachment(this.languageCode, link.name);
      default:
        return [];
    }
  }

  async addMessage(...messages: MessageEvent[]): Promise<number> {
    const length = this._messages.push(...messages);
    await this.playMessages();
    return length;
  }

  private async playMessages() {
    const messages = this.unplayedMessages;
    for (const message of messages) {
      await playEvent(message);
    }
  }
}

// this.language.expressions.filter((e, i, a) => {
//     return getWords(e.toLowerCase(), this.language.code).find(b => this.language.words.find(d => d.word === b) && a.find(c => c.includes(b)) === e)
// })

class Conversation {
  version = 1;
  shock = false;
  started = false;
  levels: Level[] = [];

  constructor(public language: Language) {
    const stored = localStorage.getItem(this.key);
    if (stored) {
      try {
        const previous = JSON.parse(stored);
        switch (previous.version) {
          case 1:
            const { levels, ...value } = previous;
            this.set(value);
            this.levels = levels.map(({_messages, ...l}: Level) => {
              const level = new Level(() => this, l);
              level._messages = _messages.map(deserialize(level)).filter(
                (a): a is MessageEvent => a != null
              )
              return level;
            });
            break;
          default: {
            writeLog('No version information for stored data');
          }
        }
      } catch (e) {
        writeError('Error parsing localstorage data', e);
        try {
          const BACKUP_KEY = this.key + '_BACKUPS';
          const backups = JSON.parse(localStorage.getItem(BACKUP_KEY) ?? '[]');
          backups.unshift(stored);
          localStorage.setItem(BACKUP_KEY, backups);
        } catch (e) {
          writeError('Error backing up localstorage data we failed to parse.', e);
        }
      }
    }

    reaction(
      () => JSON.stringify(this.value),
      (json) => localStorage.setItem(this.key, json),
      { delay: 500 }
    )

    makeAutoObservable(this);
    if (this.levels.length === 0) {
      this.addLevel();
    }
  }

  addLevel(): void {
    this.levels.push(
      new Level(() => this, {
        languageCode: this.language.code,
        level: this.levels.length + 1,
      })
    );
  }

  get wordCount() {
    return this.levels.flatMap((l) => l.learnedWords).filter((w, i, a) => a.indexOf(w) === i).length
  }

  get key(): string {
    return `StateStore-Conversation-${this.language.code}`
  }

  get level(): Level | undefined {
    return this.levels[this.levels.length - 1];
  }

  get messages(): PromptMessage[] {
    return this.level?.messages ?? [];
  }

  set(value: Partial<Conversation>) {
    Object.assign(this, value);
  }

  get value(): Pick<Conversation, 'shock' | 'started' | 'levels' | 'version'> {
    return {
      shock: this.shock,
      started: this.started,
      levels: this.levels,
      version: this.version,
    };
  }

  get typing(): TypingState {
    return this.level?.hasUnplayed ? 'typing' : 'idle';
  }

  async start(): Promise<void> {
    if (this.started) {
      return;
    }

    this.set({ started: true });
    await this.load();
  }

  async load(): Promise<void> {
    await this.level?.load();
  }

  async continue(audio: File): Promise<void> {
    return this.level?.continue(audio);
  }
}

const conversations: Partial<Record<LanguageCode, Conversation>> = {}

export const getConversation = (language: Language): Conversation => {
  return conversations[language.code] ?? (
    conversations[language.code] = new Conversation(language)
  );
}

export default Conversation;