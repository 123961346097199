// import { unmute } from './unmute';
import { makeAutoObservable } from 'mobx';
import stretch from './stretch';
import EncoderWorker from './encoderWorker?worker';
import { writeError } from '../api';
import { MicVAD } from '@ricky0123/vad-web';

export const wait = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export type Recording = { blob: Blob; buffer: BlobPart[] };

export type AudioState = 'init' | 'loading' | 'on' | 'idle' | 'mute' | 'muted';

export class Audio {
  context = new AudioContext();

  recorder: MicVAD | undefined;

  // Recording state
  state: AudioState = 'init';
  workletNode: AudioWorkletNode | null = null;
  mediaStream: MediaStreamAudioSourceNode | null = null;
  recordedAudio: { [id: string]: File } = {};

  // Play state
  stretchNode: AudioWorkletNode | null = null;
  playingAudio: AudioBufferSourceNode | null = null;
  cachedNodes: {[key: string]: AudioBuffer } = {};
  encoderWorker: Worker;

  constructor() {
    makeAutoObservable(this);
    // unmute(this.context);
    this.encoderWorker = new EncoderWorker();
    this.initialize();
  }

  async initialize(): Promise<void> {
    const stretchBlob = new Blob([stretch], { type: 'application/javascript' });
    const stretchUrl = URL.createObjectURL(stretchBlob);
    await this.context.audioWorklet.addModule(stretchUrl);
    this.stretchNode = new AudioWorkletNode(this.context, 'stretchy');
    this.stretchNode.connect(this.context.destination);
  }

  async play(audioUrl: string, options?: { rate?: number; duration?: number, cache?: boolean }): Promise<void> {
    const { rate = 1, duration, cache } = options ?? {};
    try {
      if (this.playingAudio && duration != null) {
        this.playingAudio.stop();
      }
  
      if (!this.stretchNode || audio.is('on')) {
        return;
      }

      const audioBuffer = await this.getBuffer(audioUrl, cache ?? false);

      // Fetch and decode the audio data
      if (rate === 1) {
        const a = new AudioBufferSourceNode(this.context, {
          buffer: audioBuffer,
          loop: false,
        });
        a.connect(this.context.destination);
        a.start();

        if (duration == null) {
          this.playingAudio = a;
        }

        return new Promise((resolve) => {
          setTimeout(resolve, (duration ?? audioBuffer.duration) * 1000);
        })
      }

      // Configure the worklet if needed (e.g., set rate, semitones, etc.)
      this.stretchNode.port.postMessage({
        controls: {
          rate: rate,
          semitones: 0,
          tonality: 8000,
          block: 120,
          overlap: 8,
        }
      });

      // Send audio data to the worklet for processing
      let buffers: Float32Array[] = [];
      for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
        buffers.push(audioBuffer.getChannelData(i));
      }

      // Create a buffer source node for playback
      let source = new AudioBufferSourceNode(this.context, {
        buffer: audioBuffer,
        loop: true,
      });
    
      source.connect(this.stretchNode);
    
      this.stretchNode.port.postMessage({ loopBuffers: buffers });

      // Start playback
      source.start();
      const audioDuration = audioBuffer.duration / rate;
      const audioLength = this.context.currentTime + (duration ?? audioDuration);
      source.stop(audioLength);
    
      this.playingAudio = source;

      // Handle ending of playback
      return await new Promise((resolve) => {
        source.onended = () => {
          setTimeout(() => {
            source.disconnect();
          }, (audioDuration - (duration ?? audioDuration)) * 1000);
          resolve();
        };
      });
    } catch (e) {
      writeError('Failed to play audio', e);
    }
  }

  private async getBuffer(audioUrl: string, cache: boolean): Promise<AudioBuffer> {
    if (this.cachedNodes[audioUrl]) {
      return this.cachedNodes[audioUrl];
    }
  
    const response = await fetch(audioUrl);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.context.decodeAudioData(arrayBuffer);
  
    if (cache) {
      this.cachedNodes[audioUrl] = audioBuffer;
    }
  
    return audioBuffer;
  }

  async stopPlaying(): Promise<void> {
    this.playingAudio?.stop();
  }

  private async startRecord(onDone: (file: File) => void): Promise<void> {
    if (!this.recorder) {
      this.encoderWorker.onmessage = (event) => {
        if (event.data.type === 'finished') {
          const { buffer } = event.data as { buffer: Float32Array[] };
          const blob = new Blob(buffer, { type: 'audio/mp3' });
          const file = new File(buffer, 'audio.mp3', {
            type: blob.type,
            lastModified: Date.now(),
          })
          onDone(file);
        }
      }

      this.recorder = await MicVAD.new({
        minSpeechFrames: 5,
        onSpeechStart: () => {
          this.set('on');
        },
        onSpeechEnd: (audio: Float32Array) => {
          if (this.is('mute')) {
            this.recorder?.pause();
            this.set('muted');
          } else {
            this.set('idle');
          }
          this.encoderWorker.postMessage({
            command: 'encode',
            buffer: audio,
          });
        },
      });
    }

    this.recorder.start();
  };

  async record(onDone: (file: File) => void): Promise<void> {
    if (this.is('on', 'loading', 'idle', 'mute')) {
      return;
    }

    this.set('loading');

    const { audioSession } = (navigator as any);
    if (audioSession && audioSession.type) {
      audioSession.type = 'play-and-record';
    }

    await this.startRecord(onDone);
    await wait(50);

    this.set('idle');
  }

  // Function to stop recording audio and send it to the API
  async recordStop(): Promise<void> {
    if (this.is('on')) {
      this.set('mute');
    } else {
      this.recorder?.pause();
      this.set('muted');
    }
  }

  is(...test: AudioState[]): boolean {
    return test.includes(this.state);
  }

  set(state: AudioState) {
    this.state = state;
  }
}

export const audio = new Audio();